import * as React from "react"
import { graphql } from "gatsby"

import { PostContext, PostContextProvider } from '../contexts/post.context';
import { GetPostQuery } from '../../graphql-types';
import { PostPageContext } from '../page-contexts/post-page-context';

import Layout from "../components/layout"
import Seo from "../components/seo"
import LayoutContainer from '../components/layout-container';
import PostRender from '../components/post-render';
import NotTranslated from '../components/not-translated';
import PostComments from '../components/post-comments';
import { makeStyles } from '@material-ui/core';


export interface BlogTemplateProps {
  data: GetPostQuery;
  pageContext: PostPageContext,
}

const useStyles = makeStyles({
  comments: {
    marginTop: '4em'
  }
});

const BlogTemplate: React.FC<BlogTemplateProps> = ({data, pageContext}) => {

  const classes = useStyles();

  const post = data?.mdx;
  const postProvider = PostContextProvider( post, pageContext );

  return (
    <PostContext.Provider value={postProvider}>
      <Layout>
        <Seo title={post?.frontmatter?.title} description={post?.frontmatter?.description}/>
        <LayoutContainer>

          { post ? (
            <React.Fragment>
              <PostRender post={post}/>
              <div className={classes.comments}>
                <PostComments post={post}/>
              </div>
            </React.Fragment>
          ) : <NotTranslated/>}

        </LayoutContainer>
      </Layout>
    </PostContext.Provider>
  )
}

export default BlogTemplate

export const query = graphql`
  query getPost($locale: String!, $slug: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      frontmatter {
        slug
        title
        description
        tags
        date
      }
      body
      parent {
        ... on File {
          relativeDirectory
        }
      }
    }
  }
`
