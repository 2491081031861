import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby';

import { PostContext } from '../contexts/post.context';
import { GetLanguagesCodesQuery } from '../../graphql-types';

import { useTranslation } from 'react-i18next';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    width: '95vw',
    transform: 'translate(50%, -50%)',

    textAlign: 'center',
    fontSize: '1.5em'
  },
  title: {
    fontSize: '1.5em',
    marginBottom: '1em'
  },
  subtitle: { },
  ul: {
    listStyle: 'none'
  }
});

const NotTranslated: React.FC = () => {

  const {t} = useTranslation("blog");
  const classes = useStyles();

  const postContext = React.useContext(PostContext);

  const languages = getAllLanguages()?.themeI18N?.config;

  const otherUrls = postContext?.postContext?.otherUrls;

  return (
    <div className={classes.container}>
      <div className={classes.title}>{t('notTranslated')}</div>
      <div className={classes.subtitle}>{t('notTranslatedAnotherLanguages')}</div>
      <ul className={classes.ul}>
        {
          otherUrls.map(x =>
            <li key={x?.locale + x?.slug}>
              <LocalizedLink language={x?.locale} to={x?.slug}>
                { languages?.find(y => y?.code === x?.locale)?.localName }
              </LocalizedLink>
            </li>
          )
        }
      </ul>
    </div>
  );
}
export default NotTranslated;

const getAllLanguages = () => useStaticQuery<GetLanguagesCodesQuery>(graphql`
  query getLanguagesCodes {
    themeI18N {
      config {
        code
        localName
      }
    }
  }
`);
