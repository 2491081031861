import * as React from "react"

import { GetPostQuery } from '../../graphql-types';

import { useContext } from 'react';
import { LocaleContext } from 'gatsby-theme-i18n';

import { Disqus } from 'gatsby-plugin-disqus'

interface PostCommentsProps {
  post?: GetPostQuery['mdx'];
}

const PostComments: React.FC<PostCommentsProps> = ({ post }) => {

  const locale = useContext(LocaleContext);

  const disqusConfig = {
    url: 'https://higorcavalcanti.dev/' + locale + post?.frontmatter?.slug,
    identifier: post?.parent?.relativeDirectory,
    title: post?.frontmatter?.title,
  };

  return (
    <React.Fragment>
      <Disqus config={disqusConfig}/>
    </React.Fragment>
  );
}
export default PostComments;
