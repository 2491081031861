export const copyToTransferArea = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.setAttribute('readonly', 'true');
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);

  textArea.focus();
  textArea.select();
  textArea.setSelectionRange(0, 999999);

  try {
    document.execCommand('copy');
  } catch {

  }
  document.body.removeChild(textArea);
}
