import * as React from "react"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

import { GetPostQuery } from '../../graphql-types';

import PostShare from './post-share';
import PostTagsList from './post-tags-list';

interface PostRenderProps {
  post?: GetPostQuery['mdx'];
}

const useStyles = makeStyles(theme => createStyles({
  title: {
    fontSize: '2.5em',
    marginBottom: '0.2em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    }
  },
  description: {
    fontSize: '1.5em',
    textAlign: 'center',
    fontWeight: 'normal'
  },
  hr: {
    borderColor: 'var(--color-primary)',
    opacity: 0.5,
    margin: '2em 10%'
  },
  tags: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}));

const PostRender: React.FC<PostRenderProps> = ({ post }) => {

  const classes = useStyles();

  return (
    <React.Fragment>
      <h1 className={classes.title}>
        { post?.frontmatter?.title }
      </h1>

      <div className={classes.tags}>
        <PostTagsList post={post}/>
      </div>

      <h2 className={classes.description}>
        { post?.frontmatter?.description }
      </h2>

      <PostShare post={post}/>

      <hr className={classes.hr}/>

      <section className='post-body'>
        <MDXRenderer>{post?.body}</MDXRenderer>
      </section>

      <hr className={classes.hr}/>

    </React.Fragment>
  );
}
export default PostRender;
