import * as React from "react"

import { GetPostQuery } from '../../graphql-types';
import { IconButton, makeStyles, Snackbar, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import { FaWhatsapp } from '@react-icons/all-files/fa/FaWhatsapp';
import { FaTelegramPlane } from '@react-icons/all-files/fa/FaTelegramPlane';
import { FaLink } from '@react-icons/all-files/fa/FaLink';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { copyToTransferArea } from '../utils/transfer-area';

interface PostShareProps {
  post?: GetPostQuery['mdx'];
}

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'center'
  },
});

const PostShare: React.FC<PostShareProps> = ({ post }) => {

  const { t } = useTranslation('blog');
  const classes = useStyles();

  const isBrowser = typeof window !== "undefined"
  const blogUrl = isBrowser ? window.location.origin : '';
  const postUrl = isBrowser ? window.location.href : '';
  // const blogUrl = 'https://higorcavalcanti.dev/pt/';
  // const postUrl = 'https://higorcavalcanti.dev/pt/utilizando-angular-injector/';

  const newLine = `
`;
  const shareText = `${t('justRead')} '${post.frontmatter.title}'${newLine}${t('writtenBy')}`;
  const shareTextWithLink = `${shareText}${newLine}${postUrl.toString()}`

  const urlFacebookShare = new URL('https://www.facebook.com/sharer/sharer.php');
  urlFacebookShare.searchParams.append('u', postUrl);
  urlFacebookShare.searchParams.append('quote', shareText);

  const urlLinkedinShare = new URL('https://www.linkedin.com/shareArticle');
  urlLinkedinShare.searchParams.append('mini', 'true');
  urlLinkedinShare.searchParams.append('url', postUrl);
  urlLinkedinShare.searchParams.append('title', post.frontmatter.title);
  urlLinkedinShare.searchParams.append('summary', 'Acabei de ler');
  urlLinkedinShare.searchParams.append('source', blogUrl);

  const urlTelegramShare = new URL('https://telegram.me/share/url');
  urlTelegramShare.searchParams.append('url', postUrl);
  urlTelegramShare.searchParams.append('text', shareText);

  const urlWhatsappShare = new URL('https://api.whatsapp.com/send')
  urlWhatsappShare.searchParams.append('text', shareTextWithLink)

  const urlTwitterShare = new URL('https://twitter.com/intent/tweet');
  urlTwitterShare.searchParams.append('text', shareTextWithLink);


  const [copied, setCopied] = React.useState(false);
  const openCopied = () => setCopied(true);
  const closeCopied = () => setCopied(false);
  const shareLink = () => {
    copyToTransferArea(postUrl);
    openCopied();
  }

  return (
    <div className={classes.buttons}>
      <Tooltip title={t('share.facebook')} arrow>
        <IconButton href={urlFacebookShare.toString()} target='_blank'>
          <FaFacebook/>
        </IconButton>
      </Tooltip>

      <Tooltip title={t('share.linkedin')} arrow>
        <IconButton href={urlLinkedinShare.toString()} target='_blank'>
          <FaLinkedin/>
        </IconButton>
      </Tooltip>

      <Tooltip title={t('share.telegram')} arrow>
        <IconButton href={urlTelegramShare.toString()} target='_blank'>
          <FaTelegramPlane/>
        </IconButton>
      </Tooltip>

      <Tooltip title={t('share.whatsapp')} arrow>
        <IconButton href={urlWhatsappShare.toString()} target='_blank'>
          <FaWhatsapp/>
        </IconButton>
      </Tooltip>

      <Tooltip title={t('share.twitter')} arrow>
        <IconButton href={urlTwitterShare.toString()} target='_blank'>
          <FaTwitter/>
        </IconButton>
      </Tooltip>

      <Tooltip title={t('share.link')} arrow>
        <IconButton onClick={shareLink}>
          <FaLink/>
        </IconButton>
      </Tooltip>

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                open={copied}
                message={t('copied')}
                onClose={closeCopied}
                action={
                  <IconButton size="small" aria-label="close" color="inherit" onClick={closeCopied}>
                    <FaTimes fontSize="small" />
                  </IconButton>
                }/>
    </div>
  );
}
export default PostShare;
