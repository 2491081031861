import * as React from "react"
import { Chip, makeStyles } from '@material-ui/core';
import { GetPostQuery } from '../../graphql-types';

export interface PostTagsListProps {
  post?: GetPostQuery['mdx'],
}

const useStyles = makeStyles({
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    pointerEvents: 'none',
  },
  tag: {
    marginRight: '0.5em',
    marginBottom: '0.5em',
    backgroundColor: 'var(--color-primary)',
    color: 'white'
  }
});

const PostTagsList: React.FC<PostTagsListProps> = ({ post }) => {
  const classes = useStyles();

  return (
    <div className={classes.tagList}>
      {post?.frontmatter?.tags?.map(tag => (
        <Chip key={tag} size="small" label={tag} className={classes.tag}/>
      ))}
    </div>
  );
};

export default PostTagsList;
